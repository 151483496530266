<template>
  <div class="cx-rec-detail" :class="is_mobile ? 'm-rec-detail' : 'pc-rec-detail'">
    <div class="cx-rd-banner">
      <img class="cx-rd-bg" :src="is_mobile ? bg_m : bg" />
      <div class="cx-kind-msg">
        <div class="position-name">
          {{ recuritment_info[$t("language.shot_name") + "_title"] }}
          <span>{{ recuritment_info[$t("language.shot_name") + "_category"] }} </span>
        </div>
        <div class="position-profile">
          {{
            $t("language.shot_name") == "cn"
              ? recuritment_info.district_name
              : recuritment_info.en_district_name
          }}
          | {{ recuritment_info.input_time_date }} |
          {{ recuritment_info[$t("language.shot_name") + "_recruit_number"] }}
        </div>
      </div>
      <div class="cx-join-in" @click="showJoinForm">{{ $t("recuritment.join_in") }}</div>
    </div>
    <div class="rec-recuritment-wrap">
      <img class="rec-rw-bg" :src="is_mobile ? recuritment_bg_m : recuritment_bg" />
      <div class="rec-info-box">
        <div class="rec-info-label">{{ $t("recuritment.position_responsibility") }}</div>
        <div class="rec-info-txt">
          {{ recuritment_info[$t("language.shot_name") + "_operating_duty"] }}
        </div>
        <div class="rec-info-label">{{ $t("recuritment.position_require") }}</div>
        <div class="rec-info-txt">
          {{ recuritment_info[$t("language.shot_name") + "_job_require"] }}
        </div>
      </div>
    </div>
    <cx-cover-layout v-if="show_delivery">
      <cx-submit-resume
        @hideForm="hideForm"
        :recuirt_title="recuritment_info[$t('language.shot_name') + '_title']"
        :place="recuritment_info.work_place"
        :recuirt_id="recuritment_info.id"
      ></cx-submit-resume>
    </cx-cover-layout>
  </div>
</template>
<script>
import SubmitResume from "../../components/SubmitResume.vue";
import CoverLayout from "../../components/CoverLayout.vue";

export default {
  name: "Detail",

  components: {
    "cx-submit-resume": SubmitResume,
    "cx-cover-layout": CoverLayout,
  },
  props: {},
  data() {
    return {
      bg: require("@/assets/recuritment/detail-bg.png"),
      bg_m: require("@/assets/recuritment/detail-bg-m.png"),
      recuritment_bg: require("@/assets/i-bg-a.png"),
      recuritment_bg_m: require("@/assets/series-bg.png"),

      show_delivery: false,

      recuritment_info: {},
    };
  },
  methods: {
    hideForm() {
      this.show_delivery = false;
    },
    showJoinForm() {
      this.show_delivery = true;
    },
  },
  mounted() {
    this.api
      .recruitDetail({
        id: this.$route.query.id,
      })
      .then((res) => {
        if (res.code == 200) {
          this.recuritment_info = res.data;
        }
      });
  },
};
</script>
<style lang="less" scoped>
.pc-rec-detail {
  margin-top: 100px;
}
.cx-rd-banner {
  position: relative;
  height: 270px;
}
.cx-rd-bg {
  position: absolute;
  width: 1920px;
  height: 270px;
  left: 0px;
  top: 0px;
  z-index: 0;
}
.cx-kind-msg {
  position: absolute;
  color: #fff;
  top: 90px;
  left: 150px;
  text-align: left;
}
.position-name {
  font-size: 41px;
  margin-bottom: 45px;
}
.position-name span {
  font-size: 25px;
  background-color: #3392ff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 20px;
  float: right;
}

.position-profile {
  font-size: 29px;
}

.cx-join-in {
  position: absolute;
  background-color: #3392ff;
  font-size: 28px;
  display: inline-block;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  top: 105px;
  right: 105px;
  cursor: pointer;
}

.rec-rw-bg {
  position: absolute;
  left: 0px;
  width: 1920px;
  bottom: 0px;
  z-index: 0;
}
.rec-recuritment-wrap {
  position: relative;
  min-height: 960px;
}
.rec-info-box {
  position: relative;
  z-index: 11;
  margin-top: 150px;
  margin-left: 150px;
  color: #fff;
  text-align: left;
  width: 1600px;
}
.rec-info-label {
  font-size: 33px;
}

.rec-info-txt {
  font-size: 29px;
  line-height: 56px;
  white-space: pre-line;
  margin-top: 30px;
  margin-bottom: 70px;
}
.m-rec-detail {
  margin-top: 100px;
  .cx-rd-bg {
    height: 235px;
    width: 750px;
  }
  .rec-info-box {
    width: 700px;
    margin: 90px auto 20px;
  }
  .rec-rw-bg {
    width: 750px;
    bottom: 0px;
  }
  .rec-info-txt {
    font-size: 27px;
  }
  .cx-kind-msg {
    left: 33px;
    top: 60px;
  }
  .cx-join-in {
    right: 33px;
    top: 120px;
    font-size: 32px;
  }
}
</style>
