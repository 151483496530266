<template>
  <div
    class="cx-cooperation-box"
    :class="is_mobile ? 'm-cx-cooperation-box' : 'pc-cx-cooperation-box'"
  >
    <div class="cx-co-form">
      <div class="cx-co-title">{{ $t("recuritment.delivery") }}</div>
      <div class="cx-form-wrap">
        <div class="cx-form-row">
          <div class="cx-form-label"  :class="name.invalidate ? 'cx-form-red' : ''">{{ $t("recuritment.form.name") }} *</div>
          <div class="cx-form-input">
            <input
              type="text"
              v-model="name.value"
              @blur="checkInput('name')"
              @input="checkInput('name')"
            />
          </div>
        </div>
        <div class="cx-form-row" @click="toggleGender">
          <div class="cx-form-label"  :class="gender.invalidate ? 'cx-form-red' : ''" >{{ $t("recuritment.form.gender") }} *</div>
          <div class="cx-form-input cx-form-choice">
            {{ gender.value }}
            <div class="cx-form-picker" v-if="show_gender">
              <div
                class="cx-picker-item"
                @click="checkGender(item[$t('language.shot_name')])"
                v-for="(item, i) in gender_picker"
                :key="i"
              >
                {{ item[$t("language.shot_name")] }}
              </div>
            </div>
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label"  :class="phone.invalidate ? 'cx-form-red' : ''">{{ $t("recuritment.form.mobile") }} *</div>
          <div class="cx-form-input">
            <input
              type="text"
              v-model="phone.value"
              @blur="checkInput('phone')"
              @input="checkInput('phone')"
            />
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label"  :class="email.invalidate ? 'cx-form-red' : ''">{{ $t("recuritment.form.email") }} *</div>
          <div class="cx-form-input">
            <input
              type="text"
              v-model="email.value"
              @blur="checkInput('email')"
              @input="checkInput('email')"
            />
          </div>
        </div>
        <div class="cx-form-row" @click="toggleEducation">
          <div class="cx-form-label"  :class="education.invalidate ? 'cx-form-red' : ''">{{ $t("recuritment.form.education") }} *</div>
          <div class="cx-form-input cx-form-choice">
            {{ education.value }}
            <div class="cx-form-picker" v-if="show_education">
              <div
                class="cx-picker-item"
                @click="checkEducation(item[$t('language.shot_name')])"
                v-for="(item, i) in education_level"
                :key="i"
              >
                {{ item[$t("language.shot_name")] }}
              </div>
            </div>
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label"  :class="school.invalidate ? 'cx-form-red' : ''">{{ $t("recuritment.form.graduated") }} *</div>
          <div class="cx-form-input">
            <input
              type="text"
              v-model="school.value"
              @blur="checkInput('school')"
              @input="checkInput('school')"
            />
          </div>
        </div>
        <div class="cx-form-row">
          <div class="cx-form-label"  :class="mojor.invalidate ? 'cx-form-red' : ''">{{ $t("recuritment.form.profession") }} *</div>
          <div class="cx-form-input">
            <input
              type="text"
              v-model="mojor.value"
              @blur="checkInput('mojor')"
              @input="checkInput('mojor')"
            />
          </div>
        </div>
        <div class="cx-form-row cx-form-up">
          <div class="cx-form-label">{{ $t("recuritment.form.prifle") }}</div>
          <div class="cx-form-file">
            <img v-if="resume.value" class="cx-file-icon" :src="file_exist_icon" @click="removeFile"/>
            <div v-if="!resume.value">
              +
              <input @change="uploadFile" ref="file" type="file" v-if="!show_education" />
            </div>
          </div>
        </div>
      </div>
      <div class="cx-form-submit" :class="submit_locked?'cx-form-submit-dis':''" @click="submit">
        {{ $t("recuritment.form.submit") }}
      </div>
    </div>
    <img class="co-close-btn" @click="$emit('hideForm')" :src="coop_close" />
    <slot></slot>
  </div>
</template>
<script>
import validate from "../utils/data.validata";
export default {
  name: "Cooperation",
  props: {
    recuirt_id:{
      type:Number
    },
    recuirt_title:{
      type:String
    },
    place:{
      type:Number
    }
  },
  data() {
    return {
      icon_a: require("@/assets/coop-icon-a.png"),
      icon_b: require("@/assets/coop-icon-b.png"),
      icon_c: require("@/assets/coop-icon-c.png"),
      coop_close: require("@/assets/close-coop.png"),
      file_exist_icon: require("@/assets/recuritment/file-exist-icon.png"),
      
      show_gender: false,
      select_file: "",
      show_education: false,

      name: {
        value: "",
      },
      gender: {
        value: "",
      },
      phone: {
        value: "",
      },
      email: {
        value: "",
      },
      education: {
        value: "",
      },
      school: {
        value: "",
      },
      mojor: {
        value: "",
      },
      resume: {
        value: "",
      },

      gender_picker: [
        {
          cn: "男",
          en: "male",
        },
        {
          cn: "女",
          en: "female",
        },
      ],
      education_level: [
        {
          cn: "大专",
          en: "Junior College",
        },
        {
          cn: "本科",
          en: "Bachelor",
        },
        {
          cn: "硕士",
          en: "Master",
        },
        {
          cn: "博士",
          en: "Doctor",
        },
      ],

      submit_locked:false,

    };
  },
  methods: {
    removeFile(){
      this.resume.value="";
    },
    checkInput(name) {
      if (name == "name") {
        if (!this.name.value) {
          this.name.invalidate = true;
        } else {
          this.name.invalidate = false;
          return true;
        }
      } else if (name == "gender") {
        if (!this.gender.value) {
          this.gender.invalidate = true;
        } else {
          this.gender.invalidate = false;
          return true;
        }
      } else if (name == "phone") {
        if (!this.phone.value) {
          this.phone.invalidate = true;
        } else {
          this.phone.invalidate = false;
          return true;
        }
      } else if (name == "email") {
        if (!validate.isEmail(this.email.value)) {
          this.email.invalidate = true;
        } else {
          this.email.invalidate = false;
          return true;
        }
      } else if (name == "mobile") {
        if (!this.mobile.value) {
          this.mobile.invalidate = true;
        } else {
          this.mobile.invalidate = false;
          return true;
        }
      } else if (name == "education") {
        if (!this.education.value) {
          this.education.invalidate = true;
        } else {
          this.education.invalidate = false;
          return true;
        }
      } else if (name == "school") {
        if (!this.school.value) {
          this.school.invalidate = true;
        } else {
          this.school.invalidate = false;
          return true;
        }
      } else if (name == "mojor") {
        if (!this.mojor.value) {
          this.mojor.invalidate = true;
        } else {
          this.mojor.invalidate = false;
          return true;
        }
      }
      this.$forceUpdate();
    },

    checkEducation(value) {
      this.education.value = value;
    },
    checkGender(value) {
      this.gender.value = value;
    },
    hidePannel() {
      this.show_gender = false;
      this.show_education = false;
    },
    toggleEducation() {
      this.show_education = !this.show_education;
    },
    toggleGender() {
      this.show_gender = !this.show_gender;
    },
    uploadFile() {
      this.select_file = this.$refs["file"].files[0];
      // let size=this.$refs["file"].files[0].size;
      let fd = new FormData();
      fd.append("image", this.select_file);
      this.submit_locked=true;
      this.api.upload({real_file:fd}).then((res) => {
        if(res.code==200){
          this.resume.value=res.data.url;
        }
        this.submit_locked=false;
      });
    },
    submit() {
      let that=this;
      if(this.submit_locked){
        return false;
      }
      this.submit_locked=true;
      this.api.resumeAdd({
        recuirt_id:this.recuirt_id,
        recuirt_title:this.recuirt_title,
        place:this.place,

        name:this.name.value,
        gender:this.gender.value,
        phone:this.phone.value,
        email:this.email.value,
        education:this.education.value,
        school:this.school.value,
        mojor:this.mojor.value,
        resume:this.resume.value,
      }).then(res=>{
        if(res.code==200){
          alert('提交成功');
          that.$emit('hideForm')
        }else if(res.code==400){
          alert(res.msg)
        }
        this.submit_locked=false;
      })
      
    },
  },
  components: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.cx-cooperation-box {
  position: relative;
  width: 760px;
  height: 700px;
  margin-top: -350px;
  margin-left: -380px;
  overflow: hidden;
  float: left;
}

.co-close-btn {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 22px;
  top: 22px;
}
.cx-co-form {
  width: 760px;
  float: left;
  height: 700px;
  background: #636363;
  overflow: hidden;
}
.cx-co-title {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #fff;
  margin: 51px auto 41px;
}
.cx-form-choice {
  position: relative;
  font-size: 24px;
  text-align: left;
  line-height: 40px;
}
.cx-form-choice::after {
  content: "";
  position: absolute;
  top: 15px;
  right: 10px;
  border-top: #bbb solid 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.cx-form-row {
  height: 40px;
  margin-bottom: 12px;
}
.cx-form-up {
  height: 80px;
}
.cx-form-label {
  font-size: 18px;
  margin-right: 10px;
  line-height: 40px;
  width: 170px;
  color:#fff;
  float: left;
  text-align: right;
}
.cx-form-red{
  color: #f00;
}
.cx-form-input {
  position: relative;
  width: 460px;
  height: 36px;
  float: left;
  color:#fff;
  padding: 2px 10px;
  border: 1px solid rgba(196, 196, 196, 1);
}
.cx-form-input input {
  display: block;
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  color:#fff;
  width: 460px;
}
.cx-form-file {
  position: relative;
  text-align: left;
  margin-top: 0px;
  overflow: hidden;
  width: 80px;
  font-size: 72px;
  text-align: center;
  height: 80px;
  color: #c4c4c4;
  line-height: 80px;
  border: #c4c4c4 solid 1px;
}
.cx-form-file input {
  display: block;
  font-size: 24px;
  width: 100%;
  height: 100%;
  z-index: 111;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.cx-form-submit {
  background: #FFFFFF;
  width: 264px;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  color: #000;
  margin: 40px auto 0px;
}
.cx-form-submit-dis{
  background: rgba(255, 255, 255, 0.7);
  color:#fff;
}
.cx-form-picker {
  position: absolute;
  background-color: #f1f1f1;
  font-size: 24px;
  text-align: left;
  width: 100%;
  left: 0px;
  z-index: 11;
  top: 40px;
  border: #eaeaea solid 1px;
}
.cx-picker-item {
  padding: 5px 20px;
  cursor: pointer;
  color:#000;
}
.cx-picker-item:hover {
  background-color: #bbb;
}
.cx-file-icon{
  width: 50px;
  margin-top:-9px;
}
.m-cx-cooperation-box {
  width: 690px;
  height: 840px;
  height: auto;
  margin-left: -345px;
  margin-top: -420px;

  .cx-co-form {
    width: 690px;
    height: 840px;
    .cx-co-title {
      font-size: 37px;
    }

    .cx-form-wrap {
      width: 600px;
      margin: 0 auto;
      .cx-form-row {
        padding: 5px 0px;
        .cx-form-label {
          font-size: 25px;
        }
      }
    }
    .cx-form-input {
      height: 45px;
      width: 375px;
      border: 2px solid rgba(196, 196, 196, 1);
      input {
        height: 41px;
        width: 375px;
      }
    }
  }
}
</style>
